import React, { useEffect } from 'react';
import Layout from "@components/layout";

// 言語用Jsonファイル
import { LangSetting } from "@components/language-setting.js";
import langEn from "@content/locales/en/mt4-webterminal.json";
import langJa from "@content/locales/ja/mt4-webterminal.json";

import { SeoContents } from "@components/seo-data.js" // Seo情報設定

// MetaTraderWebTerminalの読み込みとインスタンス化のコード
/* global MetaTraderWebTerminal */

const Mt4WebTerminal = ( props ) => {
  
  // 言語設定
  const setLang = LangSetting( props, langEn, langJa ).SetLang;
  const postLang = LangSetting( props, langEn, langJa ).PostLang;

  // 構造化データ用配列
  // 作成・更新日
  const date = {modified: process.env.MODIFIED};

  // Seo情報設定
  const pathName = 'mt4-webterminal'; // ファイル名
  const title = postLang["page-title"];
  const description = postLang["page-description"];
  const keywords = postLang["page-keywords"];
  const seoTag = SeoContents( pathName, setLang, title, description, keywords, date);
  
	// MT4
	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://metatraderweb.app/trade/widget.js';
		script.async = true;
		document.body.appendChild(script);

		script.onload = () => {
			new MetaTraderWebTerminal('webterminal', {
				version: 4,
				servers: ["MiltonGlobal-Platform"],
				server: "MiltonGlobal-Platform",
				utmSource: "miltonglobal.com",
				startMode: "create_demo",
				language: "en",
				colorScheme: "black_on_white"
			});
		};
		return () => {
			document.body.removeChild(script);
		};
	}, []);

  return (
  <>
    {seoTag}
    <Layout>
			<div className="" id="webterminal" langType={setLang} style={{ width: '100%', height: '600px' }} ></div>
      
    </Layout>
  </>
  )
}
export default Mt4WebTerminal